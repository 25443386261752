import * as React from 'react';
import {
  Link,
  Popover,
  StatusIndicator
} from '@cloudscape-design/components';

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

function formatDate(date) {
  const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', hour12: false });
  return `${dateFormatter.format(date * 1000)}, ${timeFormatter.format(date * 1000)}`;
}

function createLabelFunction(columnName) {
  return ({ sorted, descending }) => {
    const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
    return `${columnName}, ${sortState}.`;
  };
}

export function getStackerStatus(status) {

  let map = {
    "Pending": {
      header: "Pending Changes",
      content: "Stacker still has some changes to apply. You need to run Stacker.",
      indicator_type: "warning"
    },
    "Failed": {
      header: "Stacker Failed",
      content: "Stacker failed to complete without error. View the latest execution history for details or check the Slack channel",
      indicator_type: "error"
    },
    "Queued": {
      header: "Stacker is Queued",
      content: "Stacker is queued to run. View the latest execution history for details or check the Slack channel",
      indicator_type: "info"
    },
    "Completed": {
      header: "Stacker is in-sync",
      content: "The target AWS account is in sync with Stacker",
      indicator_type: "success"
    },
    "Running": {
      header: "Stacker is running",
      content: "Stacker is currently running. View the latest execution history for details or check the Slack channel",
      indicator_type: "in-progress"
    },
    "Migrated": {
      header: "Migration Complete",
      content: "This customer's Stacker Config was last updated as part of the January 2024 migration (when we moved away from git). It has not been run since then.",
      indicator_type: "pending"
    }
  }

  return (
    <>
      <Popover
        header={map[status]['header']}
        content={map[status]['content']}
      >
        <StatusIndicator type={map[status]['indicator_type']}>
          {status}
        </StatusIndicator>
      </Popover>
    </>
  )
}

function getStackerLastExecution(customer) {
  if ('stacker_executions' in customer && customer.stacker_executions.length > 0) {
    const last_execution = customer.stacker_executions.pop()
    return last_execution
  } else {
      return false
  }
}

export const columnDefinitions = [
  {
    id: 'customer_code',
    header: 'Customer Code',
    cell: item => <Link href={"/customers/" + item.customer_code + "/view"}>{item.customer_code}</Link>,
    ariaLabel: createLabelFunction('customer_code'),
    sortingField: 'customer_code',
    isRowHeader: true,
  },
  {
    id: 'customer_name',
    header: 'Customer Name',
    cell: item => item.customer_name,
    ariaLabel: createLabelFunction('Customer Name'),
    sortingField: 'customer_name',
  },
  {
    id: 'aws_account_id',
    header: 'AWS Account ID',
    cell: item => item.aws_account_id,
    ariaLabel: createLabelFunction('AWS Account ID'),
    sortingField: 'aws_account_id',
  },
  {
    id: 'modified_on',
    header: 'Last modified',
    cell: item => formatDate(item.modified_on),
    ariaLabel: createLabelFunction('Last modified'),
    sortingComparator: (a, b) => a.modified_on.valueOf() - b.modified_on.valueOf(),
  },
  {
    id: 'latest_stacker_run',
    header: 'Last Stacker Run',
    cell: item => {
      const last_execution = getStackerLastExecution(item)

      if (last_execution) {
        return formatDate(last_execution.started_at)
      } else {
        return (<Popover
          header="Don't panic"
          content="This just means Stacker hasn't run in a while. Consider running Stacker to validate the target account is in-sync"
        >
          N/A
        </Popover>)
      }
    },
    ariaLabel: createLabelFunction('Last Stacker Run')
  },
  {
    id: 'stacker_status',
    header: 'Stacker Status',
    cell: item => getStackerStatus(item.stacker_status),
    ariaLabel: createLabelFunction('Stacker Status'),
    sortingField: 'stacker_status',
  },
  {
    id: 'keycloak_access',
    header: 'Keycloak Access',
    cell: item => <StatusIndicator type={item.stacker_config.stacks.some(stack => stack.name === "keycloak-access") ? "success" : "error"}>
          {item.stacker_config.stacks.some(stack => stack.name === "keycloak-access") ? "Present" : "Not Present"}
        </StatusIndicator>,
    ariaLabel: createLabelFunction('Keycloak Access'),
    sortingComparator: (a, b) => a.stacker_config.stacks.some(stack => stack.name === "keycloak-access").valueOf() - b.stacker_config.stacks.some(stack => stack.name === "keycloak-access").valueOf(),
  },
  {
    id: 'observability_access',
    header: 'Observability Access',
    cell: item => <StatusIndicator type={item.stacker_config.stacks.some(stack => stack.name === "observability-automation-role") ? "success" : "error"}>
          {item.stacker_config.stacks.some(stack => stack.name === "observability-automation-role") ? "Present" : "Not Present"}
        </StatusIndicator>,
    ariaLabel: createLabelFunction('Observability Access'),
    sortingComparator: (a, b) => a.stacker_config.stacks.some(stack => stack.name === "observability-automation-role").valueOf() - b.stacker_config.stacks.some(stack => stack.name === "observability-automation-role").valueOf(),
  }
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 customers' },
    { value: 20, label: '20 customers' },
    { value: 50, label: '50 customers' },
  ],
};

const contentDisplayPreference = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'customer_code' })),
};
export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};